import Request from "../services/Request"

export default class FileRepository {
  static getUserFiles(uuid) {
    return Request.make("GET", `file/${uuid}`)
  }

  static downloadUserFile(uuid, body) {
    return Request.blob("POST", `/file/${uuid}/download`, body)
  }

  static uploadUserFiles(uuid, data) {
    return Request.make("POST", `/admin/file/${uuid}/new`, data)
  }

  static deleteUserFile(uuid, body) {
    return Request.make("POST", `/admin/file/${uuid}/delete`, body)
  }

  static getCompanyFiles(uuid) {
    return Request.make("GET", `v1/companies/${uuid}/files`)
  }

  static downloadCompanyFile(uuid, body) {
    return Request.blob("POST", `v1/companies/${uuid}/files/download`, body)
  }

  static uploadCompanyFiles(uuid, data) {
    return Request.upload(`v1/companies/${uuid}/files/new`, data)
  }

  static deleteCompanyFile(uuid, body) {
    return Request.make("POST", `v1/companies/${uuid}/files/delete`, body)
  }
}
