<template>
  <v-container fluid style="width: 100%; height: 100%">
    <div v-if="!fileListLoading">
      <op-confirmation-dialog ref="confirm"/>
      <v-simple-table v-if="files !== null && files.length > 0">
        <template v-slot:default>
          <thead>
          <tr>
            <th></th>
            <th>{{ $t("files.name") }}</th>
            <th>{{ $t("files.date") }}</th>
            <th colspan="2"></th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="file of files" :key="file.name">
            <td class="file-icon">
              <v-icon>{{ getFileIcon(file.type) }}</v-icon>
            </td>
            <td>{{ file.name }}</td>
            <td>{{ file.date | datetime }}</td>
            <td class="file-icon">
              <v-btn
                @click="downloadFile(file)"
                color="red"
                icon
                :title="$t('files.download')"
              >
                <v-icon color="red">mdi-download</v-icon>
              </v-btn>
            </td>

            <td class="file-icon">
              <v-btn
                @click="confirmDeleteFile(file)"
                color="red"
                icon
                :title="$t('files.delete')"
              >
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div v-else-if="files !== null">
        <p>{{ $t("files.noFiles") }}</p>
      </div>

      <div v-else>
        <p>{{ $t("files.errorGettingFiles") }}</p>
      </div>
    </div>

    <div v-else class="text-center">
      <v-progress-circular :color="'green'" indeterminate></v-progress-circular>
    </div>
  </v-container>
</template>

<style scoped lang="scss">
.file-icon {
  width: 5%;
}

.loader {
  margin: 0 auto;
}
</style>

<script>
import FileLogic from "@/logics/FileLogic"
import {i18n} from "@/plugins/i18n";

export default {
  name: "view-files",

  props: {
    company: null,
    companyUuid: null,
  },

  data() {
    return {
      files: null,
      fileListLoading: true,
    }
  },

  mounted() {
    this.getCompanyFiles()
  },

  watch: {
    company(newValue, oldValue) {
      this.files = null
      this.getCompanyFiles()
    },
  },

  methods: {
    getCompanyFiles() {
      this.fileListLoading = true

      const companyUuid = this.company ? this.company.uuid: this.companyUuid

      FileLogic.getCompanyFiles(companyUuid)
        .then((files) => {
          this.files = files
          this.fileListLoading = false
        })
        .catch((error) => {
          this.fileListLoading = false
        })
    },

    getFileIcon(fileType) {
      let icon = ""
      if (fileType === "application/pdf") {
        icon = "mdi-file-pdf-outline"
      } else if (/^image\/.*$/.test(fileType)) {
        icon = "mdi-image-outline"
      } else if (fileType === "application/zip") {
        icon = "mdi-zip-box"
      } else {
        icon = "mdi-file-outline"
      }

      return icon
    },

    async confirmDeleteFile(file) {
      if (
        await this.$refs.confirm.open(
          i18n.t("Confirmation of deletion"),
          i18n.t("Are you sure you want to delete this file?"),
          i18n.t("Cancel")
        )
      ) {
        this.deleteFile(file)
      }
    },

    deleteFile(file) {
      const companyUuid = this.company ? this.company.uuid: this.companyUuid

      FileLogic.deleteCompanyFile(companyUuid, file).then((res) => {
        this.getCompanyFiles()
      })
    },

    downloadFile(file) {
      const companyUuid = this.company ? this.company.uuid: this.companyUuid

      FileLogic.downloadCompanyFile(companyUuid, file).then((res) => {
      })
    },
  },
}
</script>
