import FileRepository from "../repositories/FileRepository"

export default class FileLogic {
  static async getUserFiles(user) {
    let res = await FileRepository.getUserFiles(user.uuid)

    if (res.response && res.response.data && res.response.data.files) {
      return res.response.data.files
    }

    return []
  }

  static async downloadUserFile(user, file) {
    let res = await FileRepository.downloadUserFile(user.uuid, {
      file: file.name,
    })

    const url = window.URL.createObjectURL(new Blob([res.data]))

    const link = document.createElement("a")
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(url)
      link.remove()
    }, 100)
  }

  static async uploadUserFiles(user, files) {
    let data = new FormData()

    data.append("uuid", user.uuid)

    for (const file of files) {
      data.append("userfiles[]", file)
    }

    return await FileRepository.uploadUserFiles(user.uuid, data)
  }

  static async deleteUserFile(user, file) {
    return await FileRepository.deleteUserFile(user.uuid, {
      uuid: user.uuid,
      file: file.name,
    })
  }

  static async getCompanyFiles(companyUuid) {
    let res = await FileRepository.getCompanyFiles(companyUuid)

    if (res.response && res.response.data && res.response.data.files) {
      return res.response.data.files
    }

    return []
  }

  static async downloadCompanyFile(companyUuid, file) {
    let res = await FileRepository.downloadCompanyFile(companyUuid, {
      file: file.name,
    })

    const url = window.URL.createObjectURL(new Blob([res.data]))

    const link = document.createElement("a")
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()

    setTimeout(function () {
      window.URL.revokeObjectURL(url)
      link.remove()
    }, 100)
  }

  static async uploadCompanyFiles(companyUuid, files) {
    let data = new FormData()

    data.append("uuid", companyUuid)

    for (const file of files) {
      console.log("Appending file:", file); // Debugging line
      data.append("files[]", file)
    }

    console.log("***** Checking the  FormData content:", data); // Debugging line
    console.log("data.entries ", data.entries()); // Debugging line

    // Optional: Check the FormData content
    for (let [key, value] of data.entries()) {
      console.log("***** FormData content:", key, value); // Debugging line
    }

    try{
      return await FileRepository.uploadCompanyFiles(companyUuid, data)
    } catch (error) {
      console.log("Error uploading files:", error);
    }
  }

  static async deleteCompanyFile(companyUuid, file) {
    return await FileRepository.deleteCompanyFile(companyUuid, {
      uuid: companyUuid,
      file: file.name,
    })
  }
}
